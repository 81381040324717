.ant-layout-header {
  background: transparent;
}

.ant-layout {
  background: transparent;
}

.ant-layout-sider {
  background: #fff;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px solid #e8e8e8;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-tree-title:hover {
  color: #5788fc;
}

.ant-tree li {
  margin: 7px 0;
}
.ant-tree li .ant-tree-node-content-wrapper {
  min-width: 300px;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background: none;
}

.ant-menu {
  background: transparent;
  color: #a6a6a6;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #5788fc;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #5788fc;
}

.ant-btn-primary {
  background-color: #253e87;
  border-color: #253e87;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: rgb(64, 83, 138);
  border-color: rgb(64, 83, 138);
}

.ant-page-header-heading-title {
  color: #5788fc;
}

.ant-table {
  color: #253e87;
}

.ant-table-thead > tr > th {
  background: #fff;
  color: #757575;
  font-weight: normal;
}

.ant-table-tbody {
  font-weight: bold;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0px solid transparent;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  border-bottom: 0px solid #1890ff;
}

.ant-menu-item:focus,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:focus {
  border-bottom: 0px solid #1890ff;
}

.ant-menu-horizontal {
  border-bottom: 0px solid #e8e8e8;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  border-bottom: 0px solid #e8e8e8;
}

.ant-typography {
  color: #253e87;
}

td:first-child {
  color: #1e1e2d;
}

.ant-card {
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.11);
  border: 0px solid #e8e8e8;
}
